import React from "react"
import { graphql } from "gatsby"
import LanguageSwitch from "../components/languageSwitch"
import ThemeSwitch from "../components/themeSwitch"
import SoundButton from "../components/soundToggle"
import Seo from "../components/seo.js"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout"

const Besked_modtaget = ({ location }) => {
  const { language } = useI18next()
  const index = language === "da" ? "/" : `/${language}/`
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Seo pathName={location.pathname} />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <section className="card wide">
          <h1 class="Display1" style={{ textAlign: "center" }}>
            {t("THANK_YOU_FOR_YOUR_MESSAGE")}
          </h1>
          <p style={{ textAlign: "center" }}>
            {t("A_CONFIRMATION_WAS_SENT_TO_YOUR_EMAIL")}
          </p>
          <p style={{ textAlign: "center" }}>
            {t("PLEASE_CHECK_YOUR_SPAM_FOLDER")}
          </p>
          <br />
          <p style={{ textAlign: "center" }}>{t("BEST_REGARDS")}</p>
          <div
            style={{
              width: "100%",
              maxWidth: "220px",
              margin: "0 auto",
              overflow: "hidden",
            }}
          >
            <StaticImage
              alt={t("SIGNATURE_ALT")}
              className="signature"
              src="../images/signature.png"
              placeholder="blurred"
              layout="fixed"
              width={220}
              loading="eager"
            />
          </div>
          <div>
            <ListTLink
              to={index}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <h3 style={{ textAlign: "center" }}>{t("RETURN_TO_INDEX")}</h3>
            </ListTLink>
          </div>
        </section>
      </main>
    </>
  )
}

export default Besked_modtaget

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
